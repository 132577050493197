$tile-size: 20px;
$bead-size: 15px;
$bead-sizePhone: 10px;
$svgHeight: 60px;
$svgHeightPhone: 30px;
$breakpoint-phone: 768px;
$font-family: 'Quicksand', sans-serif;

html{
  height: 100%
}
body {
  font-family: $font-family;
  box-sizing: border-box;
  background-color: #c7e6ff;
  max-height: 100%
}
svg {
  height : $svgHeight
}

.small svg {
  height : $svgHeightPhone
}
.flex{
  display: flex;
}
.flex-column{
  flex-direction: column;
}
.flex-align-center{
  align-items: center
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-justify-space-between{
  justify-content: space-between;
}
.flex-justify-center{
  justify-content: center;
}
.flex-grow{
  flex-grow: 2
}
.text-center{
  text-align: center;
}
.container {
  display: flex; /* or inline-flex */
  justify-content: space-between;
  max-height: 100%
}
.container__half {
  width:50%
}
.big-container{
  max-width:800px;
  margin: 0px auto
}
.container__left{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width : 30%
}
.container__animalWon{
  min-height: $svgHeight;
  display: flex;
  flex-wrap: wrap;
}

.container__beads {
  display: flex;
  align-items: flex-end;
}
.container__center {
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
}
.container__results {
  display: flex;
  align-items: center;
}

.container__bars {
  min-height: 180px;
  display: flex;
  align-items:flex-start;
}

.container__table {
  width: 50%;
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
.container__tiles {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}


.text-center{
  text-align: center;
}
.table__contour {
  border: solid #000 1px;
  padding: 10px 0px;
  background-color: #f1b448
}
.table__interieur {
  border: solid #000 1px;
  display: flex;
}
.beadsbar {
  margin: 5px;
  cursor: pointer;
}
.bead {
  width : $bead-size;
  height : $bead-size;
  border-radius: $bead-size;
  border: solid 1px #000
}

.tile {
  width: $tile-size;
  padding:10px;
  font-size: 30px;
  text-align:center;
  background-color: #ffdfa6;
  cursor: pointer;
}

.tile__unit {
  border: solid #000 1px;
}
.button {
  padding: 5px 10px;
  margin: 5px 10px;
  cursor: pointer;
}
.button:hover{
  background-color: #f9efde
}
.animal_position{
  position: relative;
  transition: 1s ease-in-out
}
@media (max-width: $breakpoint-phone) {
  svg {
    height : $svgHeightPhone
  }
  .container__animalWon{
    min-height: $svgHeightPhone;
  }
  .bead {
    width : $bead-sizePhone;
    height : $bead-sizePhone;
    border-radius: $bead-sizePhone;
    border: solid 1px #000
  }
  .container__bars {
    width: 50%;
    min-height: 130px;
    display: flex;
    align-items:flex-start;
  }
}

button{
  padding : 10px 20px;
  background-color: #FFF;
  cursor: pointer;
  border: solid 1px #000;
  font-family: $font-family
}

button:hover{
  background-color: #f9efde;
}
.games-link-container img{
  width: 200px
}
.games-link-container button{
  margin : 10px
}
